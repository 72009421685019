import {flow, types} from 'mobx-state-tree';
import {delay, EElement, ELogicalElement, EStructureItem, EventEmitter} from '@progress-fe/core';
import {IRFNodePort} from '@progress-fe/rf-core';
import {XYPosition} from '@xyflow/react';

import {TechProcessApi} from 'api';
import {IEntityToRefresh} from 'core/interfaces';
import {CalculationTaskRunStatus} from 'api/generated';
import {ProjectBase, RUN_DELAY_MS, TWorkzoneModel} from 'core/models';

import {
  ProjectElements,
  ProjectModels,
  ProjectReactions,
  ProjectElementsResults,
  ProjectSettings,
  ProjectTask
} from './models';

const TechProcessStore = types
  .compose(
    ProjectBase,
    types.model('TechProcessStore', {
      projectSettings: types.optional(ProjectSettings, {}),
      projectModels: types.optional(ProjectModels, {}),
      projectElements: types.optional(ProjectElements, {}),
      projectTask: types.optional(ProjectTask, {}),
      projectReactions: types.optional(ProjectReactions, {}),
      projectElementsResults: types.optional(ProjectElementsResults, {})
    })
  )
  .views((self) => ({
    get isFormsUpdating(): boolean {
      return (
        self.projectModels.isFormDataUpdating ||
        self.projectElements.isFormDataUpdating ||
        self.projectTask.isFormDataUpdating ||
        self.projectElementsResults.isFormDataUpdating ||
        self.projectReactions.isFormDataUpdating
      );
    }
  }))
  .actions((self) => ({
    _isUiStateTheSame(uuid: string | null, subUuid: string | null) {
      return !!uuid && self.uiState.entityId === uuid && self.uiState.subEntityId === subUuid;
    }
  }))
  .actions((self) => ({
    _clearAllJsonForms(): void {
      self.projectTask._clearJsonForm();
      self.projectModels._clearJsonForm();
      self.projectElements._clearJsonForm();
      self.projectElementsResults._clearJsonForm();
    },
    // This is called during first load or after running
    _reloadActiveEntity(): void {
      this._clearAllJsonForms();

      const {entityId, subEntityId} = self.uiState;

      if (self.uiState.entityType === EStructureItem.Element) {
        self.projectElements._reloadJsonForm(entityId, subEntityId).then();
        if (subEntityId) self.projectElements._loadSubWorkZone(entityId).then();
        if (!subEntityId) self.projectElements._clearSubWorkzone();
      } else if (self.uiState.entityType === EStructureItem.Model) {
        self.projectModels._reloadJsonForm(entityId, subEntityId).then();
      } else if (self.uiState.entityType === EStructureItem.Task) {
        self.projectTask._loadGraphZone().then();
        if (entityId) self.projectTask._reloadJsonForm(entityId).then();
      } else if (self.uiState.entityType === EStructureItem.ElementResult) {
        self.projectElementsResults._reloadJsonForm(entityId).then();
      }
    },
    // This is called by the event emitter
    _selectEntityById(entityId: string, subEntityId?: string): void {
      if (self._isUiStateTheSame(entityId, subEntityId ?? null)) {
        return;
      }

      this._clearAllJsonForms();

      if (self.projectElements.hasElement(entityId)) {
        self.uiState.select(EStructureItem.Element, entityId, subEntityId);
        self.projectElements._reloadJsonForm(entityId, subEntityId).then();
      } else if (self.projectModels.hasModel(entityId)) {
        self.uiState.select(EStructureItem.Model, entityId, subEntityId);
        self.projectModels._reloadJsonForm(entityId, subEntityId).then();
      } else if (self.projectTask.hasLogicalElement(entityId)) {
        self.uiState.select(EStructureItem.Task, entityId, subEntityId);
        self.projectTask._reloadJsonForm(entityId).then();
      } else if (self.projectElementsResults.hasElementResult(entityId)) {
        self.uiState.select(EStructureItem.ElementResult, entityId, subEntityId);
        self.projectElementsResults._reloadJsonForm(entityId).then();
      } else if (self.projectReactions.hasResult(entityId)) {
        self.uiState.select(EStructureItem.Reaction, entityId, subEntityId);
      }

      self.uiState._setTabIndex(0);
      self._saveUiState();
    },
    // This is called by a structure item or widget zone
    selectEntityByType(entityType: EStructureItem, uuid?: string | null, subUuid?: string | null) {
      if (self._isUiStateTheSame(uuid ?? null, subUuid ?? null)) {
        return;
      }

      this._clearAllJsonForms();
      self.uiState.select(entityType, uuid, subUuid);

      if (entityType === EStructureItem.Element && !!uuid) {
        self.projectElements._reloadJsonForm(uuid, subUuid).then();
        if (subUuid) self.projectElements._loadSubWorkZone(uuid).then();
        if (!subUuid) self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Model && !!uuid) {
        self.projectModels._reloadJsonForm(uuid, subUuid).then();
        self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Reaction) {
        self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Task) {
        self.projectTask._loadGraphZone().then();
        self.projectElements._clearSubWorkzone();
        if (uuid) self.projectTask._reloadJsonForm(uuid).then();
      } else if (entityType === EStructureItem.ElementResult) {
        if (uuid) self.projectElementsResults._reloadJsonForm(uuid).then();
        self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Settings) {
        self.projectElements._clearSubWorkzone();
      }

      self.uiState._setTabIndex(0);
      self._saveUiState();
    },
    _refreshEntities(entityToRefresh: IEntityToRefresh) {
      if (entityToRefresh.dataChanged) {
        self.projectElementsResults._reload().then();
      }
      if (entityToRefresh.modelsChanged) {
        self.projectModels._reload().then();
      }
      if (entityToRefresh.elementsChanged) {
        self.projectElements._reload().then();
      }
      if (entityToRefresh.logicalElementsChanged) {
        self.projectTask._reload().then();
      }
    },
    backToEntity(entityId?: string | null) {
      this.selectEntityByType(self.uiState.entityType, entityId ?? self.uiState.entityId);
    }
  }))
  .actions((self) => ({
    _subscribe() {
      EventEmitter.on('SelectItem', self._selectEntityById);
    },
    _unsubscribe() {
      EventEmitter.off('SelectItem', self._selectEntityById);
    },
    uninitialize() {
      this._unsubscribe();
      self._clearIntervals();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    createElement: flow(function* (type: EElement, position: XYPosition) {
      const result = yield self.projectElements.add(type, position);
      if (result.element) {
        yield self.projectElements._loadElements();
        yield self.projectElements._loadWorkZone();
        self.selectEntityByType(EStructureItem.Element, result.element.uuid);
      }

      if (result.changedEntities) {
        self._refreshEntities(result.changedEntities);
      }

      return result.element;
    }),
    duplicateElement: flow(function* (uuid: string) {
      const newUuid = yield self.projectElements.duplicate(uuid);
      if (newUuid) {
        yield self.projectElements._loadElements();
        yield self.projectElements._loadWorkZone();
        self.selectEntityByType(EStructureItem.Element, newUuid);
      }
    }),
    deleteElements: flow(function* (uuids: Array<string>) {
      const isDone = yield self.projectElements.remove(uuids);
      if (isDone) {
        yield self.projectElements._loadElements();
        yield self.projectElements._loadWorkZone();
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    async updateElementFormData(
      uuid: string,
      subUuid: string | null,
      schemaId: string,
      data: unknown,
      tabCode?: string
    ) {
      const {_updateElementFormData} = self.projectElements;
      const result = await _updateElementFormData(uuid, subUuid, schemaId, data, tabCode);
      self._clearErrorByUuid(uuid, schemaId);

      if (result.changedEntities) {
        self._refreshEntities(result.changedEntities);
      }
    }
  }))
  .actions((self) => ({
    createModel: flow(function* (uuid: string) {
      const model = yield self.projectModels.add(uuid);
      if (model) {
        yield self.projectModels._reload();
        self.selectEntityByType(EStructureItem.Model, model.uuid);
      }
    }),
    duplicateModel: flow(function* (uuid: string) {
      const newUuid = yield self.projectModels.duplicate(uuid);
      if (newUuid) {
        yield self.projectModels._reload();
        self.selectEntityByType(EStructureItem.Model, newUuid);
      }
    }),
    deleteModel: flow(function* (uuid: string) {
      const isDone = yield self.projectModels.remove(uuid);
      if (isDone) {
        yield self.projectModels._reload();
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    async updateModelFormData(
      uuid: string,
      subUuid: string | null,
      schemaId: string,
      formData: unknown
    ) {
      await self.projectModels._updateModelFormData(uuid, subUuid, schemaId, formData);
      self._clearErrorByUuid(uuid, schemaId);
    }
  }))
  .actions((self) => ({
    createLogicalElement: flow(function* (type: ELogicalElement) {
      const logicalElement = yield self.projectTask.add(type);
      if (logicalElement) {
        yield self.projectTask._loadLogicalElements();
        yield self.projectTask._loadGraphZone();
        self.selectEntityByType(EStructureItem.Task, logicalElement.uuid);
      }
    }),
    duplicateLogicalElement: flow(function* (uuid: string) {
      const newUuid = yield self.projectTask.duplicate(uuid);
      if (newUuid) {
        yield self.projectTask._loadLogicalElements();
        yield self.projectElements._loadWorkZone();
        yield self.projectTask._loadGraphZone();
        self.selectEntityByType(EStructureItem.Task, newUuid);
      }
    }),
    deleteLogicalElement: flow(function* (uuid: string) {
      const isDone = yield self.projectTask.remove(uuid);
      if (isDone) {
        yield self.projectTask._loadLogicalElements();
        yield self.projectElements._loadWorkZone();
        yield self.projectTask._loadGraphZone();
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    async updateLogicalElementFormData(uuid: string, schemaId: string, data: unknown) {
      const isWorkzone = await self.projectTask._updateLogicalElementFormData(uuid, schemaId, data);
      if (isWorkzone) {
        self.projectElements._loadWorkZone().then();
      }
    }
  }))
  .actions((self) => ({
    async updateElementResultFormData(uuid: string, schemaId: string, data: unknown) {
      await self.projectElementsResults._updateElementResultFormData(uuid, schemaId, data);
    }
  }))
  .actions((self) => ({
    connectElements: flow(function* (from: IRFNodePort, to: IRFNodePort, refresh: boolean) {
      const isDone = yield self.projectElements.connect(from, to);
      if (isDone && refresh) {
        yield self.projectElements._loadWorkZone();
        const selectedUuid = self.uiState.entityId === from.uuid ? to.uuid : from.uuid;
        self.selectEntityByType(EStructureItem.Element, selectedUuid);
      }
    }),
    disconnectElements: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      const isDone = yield self.projectElements.disconnect(from, to);
      if (isDone) {
        yield self.projectElements._loadWorkZone();
        const selectedUuid = self.uiState.entityId === from.uuid ? to.uuid : from.uuid;
        self.selectEntityByType(EStructureItem.Element, selectedUuid);
      }
    })
  }))
  .actions((self) => ({
    addComponentsToFavorite(componentsIds: string[]) {
      self.projectSettings.addToFavorites(self.projectUuid, componentsIds);
    },
    removeComponentsFromFavorite(componentsIds: string[]) {
      self.projectSettings.removeFromFavorites(self.projectUuid, componentsIds);
    }
  }))
  .actions((self) => ({
    _executeRun: flow(function* () {
      if (self.runRequest.isPending) return;

      console.info('[Store]: Calculation in progress...');
      const response: CalculationTaskRunStatus = yield self.runRequest.send(
        TechProcessApi.techProcessRunTechprocessCalculationTask.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      self._setRunResults(response ?? null);
      self._reloadActiveEntity();

      yield self.projectElementsResults._reload();
      yield self._loadJournal();

      console.info('[Store]: Calculation finished.');
      self.isRunning = false;
    })
  }))
  .actions((self) => {
    const actions = {
      _tryRun: flow(function* () {
        self._runAttempt++;
        console.info(`[Store]: Calculation techprocess attempt ${self._runAttempt}.`);
        if (!self.isFormsUpdating) {
          if (self._runWaiter) {
            clearInterval(self._runWaiter);
            self._runAttempt = 0;
          }
          yield self._executeRun();
        }
      }),
      run: flow(function* () {
        self.isRunning = true;
        yield delay(RUN_DELAY_MS);

        self._runWaiter = setInterval(() => {
          actions._tryRun().then();
        }, RUN_DELAY_MS);
      })
    };
    return actions;
  })
  .actions((self) => ({
    _reloadProjectData: flow(function* (projectUuid: string, checkpointUuid: string) {
      yield Promise.allSettled([
        self.projectSettings.init(projectUuid),
        self.projectModels.init(projectUuid, checkpointUuid),
        self.projectElements.init(projectUuid, checkpointUuid),
        self.projectTask.init(projectUuid, checkpointUuid),
        self.projectElementsResults.init(projectUuid, checkpointUuid),
        self._loadJournal()
      ]);
    })
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string, checkpointId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId, checkpointId);

      if (self.projectInfo && self.checkpointUuid) {
        yield self._reloadProjectData(self.projectInfo.uuid, self.checkpointUuid);
        self._reloadActiveEntity();
        self._subscribe();
      }

      self.isLoading = false;
    })
  }))
  .views((self) => ({
    get isWorkzoneLoading(): boolean {
      return self.projectElements.isWorkzoneLoading;
    },
    get isSubWorkzone(): boolean {
      return !!self.projectElements.subWorkzone;
    },
    get workzone(): TWorkzoneModel {
      return self.projectElements.subWorkzone || self.projectElements.workzone;
    },
    get nodeList() {
      return this.workzone.nodeList;
    },
    get edgeList() {
      return this.workzone.edgeList;
    },
    get workzoneKey() {
      return `${this.workzone.lastUpdate.toISOString()}_${this.workzone.parentUuid}`;
    }
  }));

export {TechProcessStore};
